import React from "react";

import * as Icon from 'react-feather';

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import '../../styles/home.css';

function HomePage() {

    return (
        <Layout>
            <SEO
                keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
                title="Files"
            />
            <div className="flex">
                <div className="content">
                    <div className="top-bar">
                        <div className="-intro-x breadcrumb mr-auto hidden sm:flex">Application <Icon.ChevronRight className="breadcrumb__icon" /><a href="" className="breadcrumb--active">Dashboard</a></div>
                        <div className="intro-x relative mr-3 sm:mr-6">
                            <a className="notification sm:hidden" href=""> <i data-feather="search" className="notification__icon dark:text-gray-300"></i> </a>
                            <div className="search-result">
                                <div className="search-result__content">
                                    <div className="search-result__content__title">Pages</div>
                                    <div className="mb-5">
                                        <a href="" className="flex items-center">
                                            <div className="w-8 h-8 bg-theme-18 text-theme-9 flex items-center justify-center rounded-full"><i className="w-4 h-4" data-feather="inbox"></i></div>
                                            <div className="ml-3">Mail Settings</div>
                                        </a>
                                        <a href="" className="flex items-center mt-2">
                                            <div className="w-8 h-8 bg-theme-17 text-theme-11 flex items-center justify-center rounded-full"><i className="w-4 h-4" data-feather="users"></i></div>
                                            <div className="ml-3">Users & Permissions</div>
                                        </a>
                                        <a href="" className="flex items-center mt-2">
                                            <div className="w-8 h-8 bg-theme-14 text-theme-10 flex items-center justify-center rounded-full"><i className="w-4 h-4" data-feather="credit-card"></i></div>
                                            <div className="ml-3">Transactions Report</div>
                                        </a>
                                    </div>
                                    <div className="search-result__content__title">Users</div>
                                    <div className="mb-5">
                                        <a href="" className="flex items-center mt-2">
                                            <div className="w-8 h-8 image-fit">
                                                <img alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src="dist/images/profile-7.jpg" />
                                            </div>
                                            <div className="ml-3">Robert De Niro</div>
                                            <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">robertdeniro@left4code.com</div>
                                        </a>
                                        <a href="" className="flex items-center mt-2">
                                            <div className="w-8 h-8 image-fit">
                                                <img alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src="dist/images/profile-12.jpg" />
                                            </div>
                                            <div className="ml-3">Denzel Washington</div>
                                            <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">denzelwashington@left4code.com</div>
                                        </a>
                                        <a href="" className="flex items-center mt-2">
                                            <div className="w-8 h-8 image-fit">
                                                <img alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src="dist/images/profile-11.jpg" />
                                            </div>
                                            <div className="ml-3">Denzel Washington</div>
                                            <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">denzelwashington@left4code.com</div>
                                        </a>
                                        <a href="" className="flex items-center mt-2">
                                            <div className="w-8 h-8 image-fit">
                                                <img alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src="dist/images/profile-11.jpg" />
                                            </div>
                                            <div className="ml-3">Brad Pitt</div>
                                            <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">bradpitt@left4code.com</div>
                                        </a>
                                    </div>
                                    <div className="search-result__content__title">Products</div>
                                    <a href="" className="flex items-center mt-2">
                                        <div className="w-8 h-8 image-fit">
                                            <img alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src="https://www.tibs.org.tw/images/default.jpg" />
                                        </div>
                                        <div className="ml-3">Dell XPS 13</div>
                                        <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">PC &amp; Laptop</div>
                                    </a>
                                    <a href="" className="flex items-center mt-2">
                                        <div className="w-8 h-8 image-fit">
                                            <img alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src="dist/images/preview-14.jpg" />
                                        </div>
                                        <div className="ml-3">Apple MacBook Pro 13</div>
                                        <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">PC &amp; Laptop</div>
                                    </a>
                                    <a href="" className="flex items-center mt-2">
                                        <div className="w-8 h-8 image-fit">
                                            <img alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src="dist/images/preview-10.jpg" />
                                        </div>
                                        <div className="ml-3">Sony A7 III</div>
                                        <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">Photography</div>
                                    </a>
                                    <a href="" className="flex items-center mt-2">
                                        <div className="w-8 h-8 image-fit">
                                            <img alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src="dist/images/preview-14.jpg" />
                                        </div>
                                        <div className="ml-3">Sony Master Series A9G</div>
                                        <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">Electronic</div>
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="intro-x dropdown mr-auto sm:mr-6">
                            <div className="dropdown-toggle notification notification--bullet cursor-pointer" role="button" aria-expanded="false"><i data-feather="bell" className="notification__icon dark:text-gray-300"></i></div>
                            <div className="notification-content pt-2 dropdown-menu">
                                <div className="notification-content__box dropdown-menu__content box dark:bg-dark-6">
                                    <div className="notification-content__title">Notifications</div>
                                    <div className="cursor-pointer relative flex items-center">
                                        <div className="w-12 h-12 flex-none image-fit mr-1">
                                            <img alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src="dist/images/profile-7.jpg" />
                                            <div className="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"></div>
                                        </div>
                                        <div className="ml-2 overflow-hidden">
                                            <div className="flex items-center">
                                                <a className="font-medium truncate mr-5">Robert De Niro</a>
                                                <div className="text-xs text-gray-500 ml-auto whitespace-nowrap">05:09 AM</div>
                                            </div>
                                            <div className="w-full truncate text-gray-600 mt-0.5">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomi</div>
                                        </div>
                                    </div>
                                    <div className="cursor-pointer relative flex items-center mt-5">
                                        <div className="w-12 h-12 flex-none image-fit mr-1">
                                            <img alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src="dist/images/profile-12.jpg" />
                                            <div className="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"></div>
                                        </div>
                                        <div className="ml-2 overflow-hidden">
                                            <div className="flex items-center">
                                                <a className="font-medium truncate mr-5">Denzel Washington</a>
                                                <div className="text-xs text-gray-500 ml-auto whitespace-nowrap">01:10 PM</div>
                                            </div>
                                            <div className="w-full truncate text-gray-600 mt-0.5">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem</div>
                                        </div>
                                    </div>
                                    <div className="cursor-pointer relative flex items-center mt-5">
                                        <div className="w-12 h-12 flex-none image-fit mr-1">
                                            <img alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src="dist/images/profile-11.jpg" />
                                            <div className="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"></div>
                                        </div>
                                        <div className="ml-2 overflow-hidden">
                                            <div className="flex items-center">
                                                <a  className="font-medium truncate mr-5">Denzel Washington</a>
                                                <div className="text-xs text-gray-500 ml-auto whitespace-nowrap">05:09 AM</div>
                                            </div>
                                            <div className="w-full truncate text-gray-600 mt-0.5">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem</div>
                                        </div>
                                    </div>
                                    <div className="cursor-pointer relative flex items-center mt-5">
                                        <div className="w-12 h-12 flex-none image-fit mr-1">
                                            <img alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src="dist/images/profile-11.jpg" />
                                            <div className="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"></div>
                                        </div>
                                        <div className="ml-2 overflow-hidden">
                                            <div className="flex items-center">
                                                <a  className="font-medium truncate mr-5">Brad Pitt</a>
                                                <div className="text-xs text-gray-500 ml-auto whitespace-nowrap">05:09 AM</div>
                                            </div>
                                            <div className="w-full truncate text-gray-600 mt-0.5">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomi</div>
                                        </div>
                                    </div>
                                    <div className="cursor-pointer relative flex items-center mt-5">
                                        <div className="w-12 h-12 flex-none image-fit mr-1">
                                            <img alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src="dist/images/profile-1.jpg" />
                                            <div className="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"></div>
                                        </div>
                                        <div className="ml-2 overflow-hidden">
                                            <div className="flex items-center">
                                                <a className="font-medium truncate mr-5">Tom Cruise</a>
                                                <div className="text-xs text-gray-500 ml-auto whitespace-nowrap">03:20 PM</div>
                                            </div>
                                            <div className="w-full truncate text-gray-600 mt-0.5">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="intro-x dropdown w-8 h-8">
                            <div className="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in" role="button" aria-expanded="false">
                                <img alt="Rubick Tailwind HTML Admin Template" src="dist/images/profile-15.jpg" />
                            </div>
                            <div className="dropdown-menu w-56">
                                <div className="dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white">
                                    <div className="p-4 border-b border-theme-27 dark:border-dark-3">
                                        <div className="font-medium">Robert De Niro</div>
                                        <div className="text-xs text-theme-28 mt-0.5 dark:text-gray-600">Software Engineer</div>
                                    </div>
                                    <div className="p-2">
                                        <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"> <i data-feather="user" className="w-4 h-4 mr-2"></i> Profile </a>
                                        <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"> <i data-feather="edit" className="w-4 h-4 mr-2"></i> Add Account </a>
                                        <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"> <i data-feather="lock" className="w-4 h-4 mr-2"></i> Reset Password </a>
                                        <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"> <i data-feather="help-circle" className="w-4 h-4 mr-2"></i> Help </a>
                                    </div>
                                    <div className="p-2 border-t border-theme-27 dark:border-dark-3">
                                        <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"> <i data-feather="toggle-right" className="w-4 h-4 mr-2"></i> Logout </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6 mt-8">
                        <div className="col-span-12 lg:col-span-3 2xl:col-span-2">
                            <h2 className="intro-y text-lg font-medium mr-auto mt-2 ">File Manager</h2>
                            <div className="intro-y box p-5 mt-6 bg-tertiary">
                                <div className="mt-1">
                                    <a href="" className="flex items-center px-3 py-2 rounded-md bg-theme-1 text-white font-medium"> <Icon.Image className="w-4 h-4 mr-2" /> Images </a>
                                    <a href="" className="flex items-center px-3 py-2 mt-2 rounded-md"> <Icon.Video className="w-4 h-4 mr-2" /> Videos </a>
                                    <a href="" className="flex items-center px-3 py-2 mt-2 rounded-md"> <Icon.File className="w-4 h-4 mr-2" /> Documents </a>
                                    <a href="" className="flex items-center px-3 py-2 mt-2 rounded-md"> <Icon.Share className="w-4 h-4 mr-2" /> Shared </a>
                                    <a href="" className="flex items-center px-3 py-2 mt-2 rounded-md"> <Icon.Trash className="w-4 h-4 mr-2" /> Trash </a>
                                </div>
                                <div className="border-t border-gray-200 dark:border-dark-5 mt-4 pt-4">
                                    <a href="" className="flex items-center px-3 py-2 rounded-md">
                                        <div className="w-2 h-2 bg-blue-800 rounded-full mr-3"></div>
                                        Custom Work
                                    </a>
                                    <a href="" className="flex items-center px-3 py-2 mt-2 rounded-md">
                                        <div className="w-2 h-2 bg-red-600 rounded-full mr-3"></div>
                                        Important Meetings
                                    </a>
                                    <a href="" className="flex items-center px-3 py-2 mt-2 rounded-md">
                                        <div className="w-2 h-2 bg-blue-400 rounded-full mr-3"></div>
                                        Work
                                    </a>
                                    <a href="" className="flex items-center px-3 py-2 mt-2 rounded-md">
                                        <div className="w-2 h-2 bg-white rounded-full mr-3"></div>
                                        Design
                                    </a>
                                    <a href="" className="flex items-center px-3 py-2 mt-2 rounded-md">
                                        <div className="w-2 h-2 bg-yellow-200 rounded-full mr-3"></div>
                                        Next Week
                                    </a>
                                    <a href="" className="flex items-center px-3 py-2 mt-2 rounded-md"> <Icon.Plus className="w-4 h-4 mr-2" /> Add New Label </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 lg:col-span-9 2xl:col-span-10">
                            <div className="intro-y flex flex-col-reverse sm:flex-row items-center">
                                <div className="w-full sm:w-auto relative mr-auto mt-3 sm:mt-0">
                                    <Icon.Search className="w-4 h-4 absolute my-auto inset-y-0 ml-3 left-0 z-10 text-gray-700 dark:text-gray-300" />
                                    <input type="text" className="form-control w-full sm:w-64 box px-10 text-gray-300" placeholder="Search files" />
                                    <div className="inbox-filter dropdown absolute inset-y-0 mr-3 right-0 flex items-center" data-placement="bottom-start">
                                        <i className="dropdown-toggle w-4 h-4 cursor-pointer text-gray-700 dark:text-gray-300" role="button" aria-expanded="false" data-feather="chevron-down"></i>
                                        <Icon.ChevronDown className="dropdown-toggle w-4 h-4 cursor-pointer text-gray-700 dark:text-gray-300" />
                                        <div className="inbox-filter__dropdown-menu dropdown-menu pt-2">
                                            <div className="dropdown-menu__content box p-5">
                                                <div className="grid grid-cols-12 gap-4 gap-y-3">
                                                    <div className="col-span-6">
                                                        <label htmlFor="input-filter-1" className="form-label text-xs">File Name</label>
                                                        <input id="input-filter-1" type="text" className="form-control flex-1" placeholder="Type the file name" />
                                                    </div>
                                                    <div className="col-span-6">
                                                        <label htmlFor="input-filter-2" className="form-label text-xs">Shared With</label>
                                                        <input id="input-filter-2" type="text" className="form-control flex-1" placeholder="example@gmail.com" />
                                                    </div>
                                                    <div className="col-span-6">
                                                        <label htmlFor="input-filter-3" className="form-label text-xs">Created At</label>
                                                        <input id="input-filter-3" type="text" className="form-control flex-1" placeholder="Important Meeting" />
                                                    </div>
                                                    <div className="col-span-6">
                                                        <label htmlFor="input-filter-4" className="form-label text-xs">Size</label>
                                                        <select id="input-filter-4" className="form-select flex-1">
                                                            <option>10</option>
                                                            <option>25</option>
                                                            <option>35</option>
                                                            <option>50</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-span-12 flex items-center mt-3">
                                                        <button className="btn btn-secondary w-32 ml-auto">Create Filter</button>
                                                        <button className="btn btn-primary w-32 ml-2">Search</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full sm:w-auto flex">
                                    <button className="btn btn-primary shadow-md mr-2">Upload New Files</button>
                                    <div className="dropdown">
                                        <button className="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300" aria-expanded="false">
                                            <span className="w-5 h-5 flex items-center justify-center"> <Icon.Plus className="w-4 h-4 mr-2" /> </span>
                                        </button>
                                        <div className="dropdown-menu w-40">
                                            <div className="dropdown-menu__content box bg-dark-1 p-2">
                                                <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="file" className="w-4 h-4 mr-2"></i> Share Files </a>
                                                <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="settings" className="w-4 h-4 mr-2"></i> Settings </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="intro-y grid grid-cols-12 gap-3 sm:gap-6 mt-5 ">
                                <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-tertiary">
                                        <div className="absolute left-0 top-0 mt-3 ml-3">
                                            <input className="form-check-input border border-gray-500" type="checkbox" />
                                        </div>
                                        <a href="" className="w-3/5 file__icon file__icon--directory mx-auto"></a> <a href="" className="block font-medium mt-4 text-center truncate">Repository</a>
                                        <div className="text-gray-600 text-xs text-center mt-0.5">20 KB</div>
                                        <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                                            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"> <Icon.MoreVertical className="w-5 h-5 text-gray-600" /> </a>
                                            <div className="dropdown-menu w-40">
                                                <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" className="w-4 h-4 mr-2"></i> Share File </a>
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-tertiary">
                                        <div className="absolute left-0 top-0 mt-3 ml-3">
                                            <input className="form-check-input border border-gray-500" type="checkbox" />
                                        </div>
                                        <a href="" className="w-3/5 file__icon file__icon--directory mx-auto"></a> <a href="" className="block font-medium mt-4 text-center truncate">Repository</a>
                                        <div className="text-gray-600 text-xs text-center mt-0.5">20 KB</div>
                                        <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                                            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"> <Icon.MoreVertical className="w-5 h-5 text-gray-600" /> </a>
                                            <div className="dropdown-menu w-40">
                                                <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" className="w-4 h-4 mr-2"></i> Share File </a>
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-tertiary">
                                        <div className="absolute left-0 top-0 mt-3 ml-3">
                                            <input className="form-check-input border border-gray-500" type="checkbox" />
                                        </div>
                                        <a href="" className="w-3/5 file__icon file__icon--empty-directory mx-auto"></a> <a href="" className="block font-medium mt-4 text-center truncate">Laravel 7</a>
                                        <div className="text-gray-600 text-xs text-center mt-0.5">120 MB</div>
                                        <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                                            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="true"> <Icon.MoreVertical className="w-5 h-5 text-gray-600" /> </a>
                                            <div className="dropdown-menu w-40">
                                                <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" className="w-4 h-4 mr-2"></i> Share File </a>
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-tertiary">
                                        <div className="absolute left-0 top-0 mt-3 ml-3">
                                            <input className="form-check-input border border-gray-500" type="checkbox" />
                                        </div>
                                        <a href="" className="w-3/5 file__icon file__icon--file mx-auto">
                                            <div className="file__icon__file-name">PHP</div>
                                        </a>
                                        <a href="" className="block font-medium mt-4 text-center truncate">Routes.php</a>
                                        <div className="text-gray-600 text-xs text-center mt-0.5">1 KB</div>
                                        <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                                            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"> <Icon.MoreVertical className="w-5 h-5 text-gray-600" /> </a>
                                            <div className="dropdown-menu w-40">
                                                <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" className="w-4 h-4 mr-2"></i> Share File </a>
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-tertiary">
                                        <div className="absolute left-0 top-0 mt-3 ml-3">
                                            <input className="form-check-input border border-gray-500" type="checkbox" />
                                        </div>
                                        <a href="" className="w-3/5 file__icon file__icon--empty-directory mx-auto"></a> <a href="" className="block font-medium mt-4 text-center truncate">Laravel 7</a>
                                        <div className="text-gray-600 text-xs text-center mt-0.5">120 MB</div>
                                        <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                                            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"> <Icon.MoreVertical className="w-5 h-5 text-gray-600" /> </a>
                                            <div className="dropdown-menu w-40">
                                                <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" className="w-4 h-4 mr-2"></i> Share File </a>
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-tertiary">
                                        <div className="absolute left-0 top-0 mt-3 ml-3">
                                            <input className="form-check-input border border-gray-500" type="checkbox" />
                                        </div>
                                        <a href="" className="w-3/5 file__icon file__icon--file mx-auto">
                                            <div className="file__icon__file-name">PHP</div>
                                        </a>
                                        <a href="" className="block font-medium mt-4 text-center truncate">Routes.php</a>
                                        <div className="text-gray-600 text-xs text-center mt-0.5">1 KB</div>
                                        <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                                            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"> <Icon.MoreVertical className="w-5 h-5 text-gray-600" /> </a>
                                            <div className="dropdown-menu w-40">
                                                <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" className="w-4 h-4 mr-2"></i> Share File </a>
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-tertiary">
                                        <div className="absolute left-0 top-0 mt-3 ml-3">
                                            <input className="form-check-input border border-gray-500" type="checkbox" />
                                        </div>
                                        <a href="" className="w-3/5 file__icon file__icon--directory mx-auto"></a>
                                        <a href="" className="block font-medium mt-4 text-center truncate">Dota 2</a>
                                        <div className="text-gray-600 text-xs text-center mt-0.5">112 GB</div>
                                        <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                                            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"> <i data-feather="more-vertical" className="w-5 h-5 text-gray-600"> </i> </a>
                                            <div className="dropdown-menu w-40">
                                                <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" className="w-4 h-4 mr-2"></i> Share File </a>
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-tertiary">
                                        <div className="absolute left-0 top-0 mt-3 ml-3">
                                            <input className="form-check-input border border-gray-500" type="checkbox" />
                                        </div>
                                        <a href="" className="w-3/5 file__icon file__icon--image mx-auto">
                                            <div className="file__icon--image__preview image-fit">
                                                <img alt="Rubick Tailwind HTML Admin Template" src="https://www.tibs.org.tw/images/default.jpg" />
                                            </div>
                                        </a>
                                        <a href="" className="block font-medium mt-4 text-center truncate">preview-2.jpg</a>
                                        <div className="text-gray-600 text-xs text-center mt-0.5">1 MB</div>
                                        <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                                            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"> <Icon.MoreVertical className="w-5 h-5 text-gray-600" /> </a>
                                            <div className="dropdown-menu w-40">
                                                <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" className="w-4 h-4 mr-2"></i> Share File </a>
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-tertiary">
                                        <div className="absolute left-0 top-0 mt-3 ml-3">
                                            <input className="form-check-input border border-gray-500" type="checkbox" />
                                        </div>
                                        <a href="" className="w-3/5 file__icon file__icon--file mx-auto">
                                            <div className="file__icon__file-name">PHP</div>
                                        </a>
                                        <a href="" className="block font-medium mt-4 text-center truncate">Routes.php</a>
                                        <div className="text-gray-600 text-xs text-center mt-0.5">1 KB</div>
                                        <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                                            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"> <Icon.MoreVertical className="w-5 h-5 text-gray-600" /> </a>
                                            <div className="dropdown-menu w-40">
                                                <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" className="w-4 h-4 mr-2"></i> Share File </a>
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-tertiary">
                                        <div className="absolute left-0 top-0 mt-3 ml-3">
                                            <input className="form-check-input border border-gray-500" type="checkbox" />
                                        </div>
                                        <a href="" className="w-3/5 file__icon file__icon--image mx-auto">
                                            <div className="file__icon--image__preview image-fit">
                                                <img alt="Rubick Tailwind HTML Admin Template" src="https://www.tibs.org.tw/images/default.jpg" />
                                            </div>
                                        </a>
                                        <a href="" className="block font-medium mt-4 text-center truncate">preview-18.jpg</a>
                                        <div className="text-gray-600 text-xs text-center mt-0.5">1.2 MB</div>
                                        <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                                            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"> <Icon.MoreVertical className="w-5 h-5 text-gray-600" /> </a>
                                            <div className="dropdown-menu w-40">
                                                <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" className="w-4 h-4 mr-2"></i> Share File </a>
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-tertiary">
                                        <div className="absolute left-0 top-0 mt-3 ml-3">
                                            <input className="form-check-input border border-gray-500" type="checkbox" />
                                        </div>
                                        <a href="" className="w-3/5 file__icon file__icon--file mx-auto">
                                            <div className="file__icon__file-name">TXT</div>
                                        </a>
                                        <a href="" className="block font-medium mt-4 text-center truncate">Resources.txt</a>
                                        <div className="text-gray-600 text-xs text-center mt-0.5">2.2 MB</div>
                                        <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                                            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"> <Icon.MoreVertical className="w-5 h-5 text-gray-600" /> </a>
                                            <div className="dropdown-menu w-40">
                                                <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" className="w-4 h-4 mr-2"></i> Share File </a>
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-tertiary">
                                        <div className="absolute left-0 top-0 mt-3 ml-3">
                                            <input className="form-check-input border border-gray-500" type="checkbox" />
                                        </div>
                                        <a href="" className="w-3/5 file__icon file__icon--file mx-auto">
                                            <div className="file__icon__file-name">PHP</div>
                                        </a>
                                        <a href="" className="block font-medium mt-4 text-center truncate">Routes.php</a>
                                        <div className="text-gray-600 text-xs text-center mt-0.5">1 KB</div>
                                        <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                                            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"> <Icon.MoreVertical className="w-5 h-5 text-gray-600" /> </a>
                                            <div className="dropdown-menu w-40">
                                                <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" className="w-4 h-4 mr-2"></i> Share File </a>
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-tertiary">
                                        <div className="absolute left-0 top-0 mt-3 ml-3">
                                            <input className="form-check-input border border-gray-500" type="checkbox" />
                                        </div>
                                        <a href="" className="w-3/5 file__icon file__icon--image mx-auto">
                                            <div className="file__icon--image__preview image-fit">
                                                <img alt="Rubick Tailwind HTML Admin Template" src="https://www.tibs.org.tw/images/default.jpg" />
                                            </div>
                                        </a>
                                        <a href="" className="block font-medium mt-4 text-center truncate">preview-11.jpg</a>
                                        <div className="text-gray-600 text-xs text-center mt-0.5">1.2 MB</div>
                                        <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                                            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"> <Icon.MoreVertical className="w-5 h-5 text-gray-600" /> </a>
                                            <div className="dropdown-menu w-40">
                                                <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" className="w-4 h-4 mr-2"></i> Share File </a>
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-tertiary">
                                        <div className="absolute left-0 top-0 mt-3 ml-3">
                                            <input className="form-check-input border border-gray-500" type="checkbox" />
                                        </div>
                                        <a href="" className="w-3/5 file__icon file__icon--file mx-auto">
                                            <div className="file__icon__file-name">PHP</div>
                                        </a>
                                        <a href="" className="block font-medium mt-4 text-center truncate">Routes.php</a>
                                        <div className="text-gray-600 text-xs text-center mt-0.5">1 KB</div>
                                        <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                                            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"> <Icon.MoreVertical className="w-5 h-5 text-gray-600" /> </a>
                                            <div className="dropdown-menu w-40">
                                                <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" className="w-4 h-4 mr-2"></i> Share File </a>
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-tertiary">
                                        <div className="absolute left-0 top-0 mt-3 ml-3">
                                            <input className="form-check-input border border-gray-500" type="checkbox" />
                                        </div>
                                        <a href="" className="w-3/5 file__icon file__icon--directory mx-auto"></a> <a href="" className="block font-medium mt-4 text-center truncate">Repository</a>
                                        <div className="text-gray-600 text-xs text-center mt-0.5">20 KB</div>
                                        <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                                            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"> <Icon.MoreVertical className="w-5 h-5 text-gray-600" /> </a>
                                            <div className="dropdown-menu w-40">
                                                <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" className="w-4 h-4 mr-2"></i> Share File </a>
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-tertiary">
                                        <div className="absolute left-0 top-0 mt-3 ml-3">
                                            <input className="form-check-input border border-gray-500" type="checkbox" />
                                        </div>
                                        <a href="" className="w-3/5 file__icon file__icon--empty-directory mx-auto"></a> <a href="" className="block font-medium mt-4 text-center truncate">Documentation</a>
                                        <div className="text-gray-600 text-xs text-center mt-0.5">4 MB</div>
                                        <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                                            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"> <Icon.MoreVertical className="w-5 h-5 text-gray-600" /> </a>
                                            <div className="dropdown-menu w-40">
                                                <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" className="w-4 h-4 mr-2"></i> Share File </a>
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-tertiary">
                                        <div className="absolute left-0 top-0 mt-3 ml-3">
                                            <input className="form-check-input border border-gray-500" type="checkbox" />
                                        </div>
                                        <a href="" className="w-3/5 file__icon file__icon--directory mx-auto"></a> <a href="" className="block font-medium mt-4 text-center truncate">Repository</a>
                                        <div className="text-gray-600 text-xs text-center mt-0.5">20 KB</div>
                                        <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                                            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"> <Icon.MoreVertical className="w-5 h-5 text-gray-600" /> </a>
                                            <div className="dropdown-menu w-40">
                                                <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" className="w-4 h-4 mr-2"></i> Share File </a>
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-tertiary">
                                        <div className="absolute left-0 top-0 mt-3 ml-3">
                                            <input className="form-check-input border border-gray-500" type="checkbox" />
                                        </div>
                                        <a href="" className="w-3/5 file__icon file__icon--empty-directory mx-auto"></a> <a href="" className="block font-medium mt-4 text-center truncate">Documentation</a>
                                        <div className="text-gray-600 text-xs text-center mt-0.5">4 MB</div>
                                        <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                                            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"> <Icon.MoreVertical className="w-5 h-5 text-gray-600" /> </a>
                                            <div className="dropdown-menu w-40">
                                                <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" className="w-4 h-4 mr-2"></i> Share File </a>
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-tertiary">
                                        <div className="absolute left-0 top-0 mt-3 ml-3">
                                            <input className="form-check-input border border-gray-500" type="checkbox" />
                                        </div>
                                        <a href="" className="w-3/5 file__icon file__icon--directory mx-auto"></a> <a href="" className="block font-medium mt-4 text-center truncate">Dota 2</a>
                                        <div className="text-gray-600 text-xs text-center mt-0.5">112 GB</div>
                                        <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                                            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"> <Icon.MoreVertical className="w-5 h-5 text-gray-600" /> </a>
                                            <div className="dropdown-menu w-40">
                                                <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" className="w-4 h-4 mr-2"></i> Share File </a>
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="intro-y col-span-6 sm:col-span-4 md:col-span-3 2xl:col-span-2">
                                    <div className="file box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in bg-tertiary">
                                        <div className="absolute left-0 top-0 mt-3 ml-3">
                                            <input className="form-check-input border border-gray-500" type="checkbox" />
                                        </div>
                                        <a href="" className="w-3/5 file__icon file__icon--file mx-auto">
                                            <div className="file__icon__file-name">MP4</div>
                                        </a>
                                        <a href="" className="block font-medium mt-4 text-center truncate">Celine Dion - Ashes.mp4</a>
                                        <div className="text-gray-600 text-xs text-center mt-0.5">20 MB</div>
                                        <div className="absolute top-0 right-0 mr-2 mt-2 dropdown ml-auto">
                                            <a className="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"> <Icon.MoreVertical className="w-5 h-5 text-gray-600" /> </a>
                                            <div className="dropdown-menu w-40">
                                                <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="users" className="w-4 h-4 mr-2"></i> Share File </a>
                                                    <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="trash" className="w-4 h-4 mr-2"></i> Delete </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="intro-y flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-6">
                                <ul className="pagination">
                                    <li>
                                        <a className="pagination__link" href=""> <i className="w-4 h-4" data-feather="chevrons-left"></i> </a>
                                    </li>
                                    <li>
                                        <a className="pagination__link" href=""> <i className="w-4 h-4" data-feather="chevron-left"></i> </a>
                                    </li>
                                    <li><a className="pagination__link" href="">...</a></li>
                                    <li><a className="pagination__link" href="">1</a></li>
                                    <li><a className="pagination__link pagination__link--active" href="">2</a></li>
                                    <li><a className="pagination__link" href="">3</a></li>
                                    <li><a className="pagination__link" href="">...</a></li>
                                    <li>
                                        <a className="pagination__link" href=""> <i className="w-4 h-4" data-feather="chevron-right"></i> </a>
                                    </li>
                                    <li>
                                        <a className="pagination__link" href=""> <i className="w-4 h-4" data-feather="chevrons-right"></i> </a>
                                    </li>
                                </ul>
                                <select className="w-20 form-select box mt-3 sm:mt-0">
                                    <option>10</option>
                                    <option>25</option>
                                    <option>35</option>
                                    <option>50</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout >
    );
}

export default HomePage;
